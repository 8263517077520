






























import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import { TYPE_TEXT } from "@/constant";

@Component({})
export default class CardItem extends Vue {
  @Prop({ default: "0 0 8px 0" }) private readonly margin!: string;
  @Prop({ default: "" }) private readonly group_cover!: string;
  @Prop({ default: "" }) private readonly group_title!: string;
  @Prop({ default: "" }) private readonly code_record!: string;
  @Prop({ default: 0 }) private readonly code_update_time!: number;
  @Prop({ default: 0 }) private readonly group_character!: number;
  @Prop({ default: true }) private readonly item_list!: boolean;

  showCharacter = true;

  get character() {
    const { group_character } = this;
    return TYPE_TEXT[group_character];
  }

  get characterStyle() {
    const { group_character } = this;
    return {
      backgroundColor: `var(--card-color-${group_character})`,
      color: "var(--theme-color)",
    };
  }

  get fromAtTime() {
    return moment(this.code_update_time * 1000).format("YYYY-MM-DD HH:mm:ss");
  }
}
