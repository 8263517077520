

































import { Component, Vue } from "vue-property-decorator";
import { List } from "vant";
import { IVoucherV2, VoucherApi } from "@/api/voucher.api";
import CardItem from "@/components/CardItem.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import { observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    List,
    CardItem,
    EmptyBox,
  },
})
export default class MyVoucher extends Vue {
  items: IVoucherV2[] = [];
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;

  get empty(): boolean {
    const { items, finished } = this;
    return !items.length && finished;
  }

  mounted() {
    observer("refreshInit", () => {
      this.page = 0;
      this.loading = false;
      this.finished = false;
      this.loadData();
    });
    InteractionLib.setWebViewTitle('我的卡券')

  }

  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, items } = this;
    const rows = await VoucherApi.getMyVouchersV2(1, page, page_size).catch(
      () => []
    );
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.items = page > 1 ? [...items, ...rows] : rows;
  }
}
